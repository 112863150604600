import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import {
  useFetchMappingQuery,
  useFetchTeUserQuery,
  useLazyFetchTeUserQuery,
} from "./api/te";
import {
  FetchBaseQueryError,
  QueryStatus,
  TypedUseQueryHookResult,
} from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { TE_ROOT_USER } from "@timeedit/types/lib/constants/permissions.constant";
import { useMemo, useState } from "react";
import {
  ITEToken,
  Mapping,
  TTEUser,
  createMappingInstance,
  isDefined,
} from "@timeedit/registration-shared";
import { SerializedError } from "@reduxjs/toolkit";

type UseUserDataType = TypedUseQueryHookResult<
  TTEUser & ITEToken,
  ITEToken | undefined,
  typeof baseQuery
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
const noRefetch: any = () => {};

export function useUserData(): UseUserDataType {
  const { loginData } = useAppSelector((state) => state.login);

  const queryProps = loginData ?? ({} as ITEToken);
  const rootData = rootDataWithRefetch(queryProps);
  const response = useFetchTeUserQuery(queryProps, {
    skip: loginData === undefined && rootData !== undefined,
  });
  return rootData ?? isDefined(response.data)
    ? response
    : {
        isError: false,
        isLoading: true,
        data: undefined,
        currentData: undefined,
        fulfilledTimeStamp: new Date().getTime(),
        error: undefined,
        isFetching: true,
        isSuccess: false,
        isUninitialized: false,
        status: QueryStatus.pending,
        refetch: noRefetch,
      };

  function rootDataWithRefetch(data: ITEToken) {
    const rootUserData = getRootUserData(data);
    return rootUserData ? { ...rootUserData, refetch: noRefetch } : undefined;
  }
}

export function useLazyUserData() {
  const { loginData } = useAppSelector((state) => state.login);

  const [triggerFetchUserData, useFetchTeUserResponse] =
    useLazyFetchTeUserQuery();

  const initialData = {
    isError: false,
    isLoading: true,
    data: undefined,
    currentData: undefined,
    fulfilledTimeStamp: 0,
    error: undefined,
    isFetching: false,
    isSuccess: false,
    isUninitialized: false,
    status: QueryStatus.pending,
    refetch: noRefetch,
  } satisfies UseUserDataType;

  const [userResponse, setUserResponse] =
    useState<UseUserDataType>(initialData);

  const triggerUserData = (props?: ITEToken) => {
    if (props !== undefined) {
      const rootUserData = getRootUserData(props);
      if (rootUserData !== undefined) {
        setUserResponse(() => rootUserData);
        return undefined;
      }
    }

    if (loginData !== undefined) {
      return triggerFetchUserData(loginData);
    }
    return undefined;
  };

  return [triggerUserData, useFetchTeUserResponse ?? userResponse] as const;
}

function getRootUserData(props: ITEToken): UseUserDataType | undefined {
  // Root users are not stored as a user in the database, so we just want to use the data from the login token in that case (which is stored in rootUserData).
  if (props.scopes.includes(TE_ROOT_USER)) {
    if (props !== undefined) {
      return {
        isError: false,
        isLoading: false,
        data: { ...props },
        currentData: { ...props },
        fulfilledTimeStamp: new Date().getTime(),
        error: undefined,
        isFetching: false,
        isSuccess: true,
        isUninitialized: false,
        status: QueryStatus.fulfilled,
        refetch: noRefetch,
      } satisfies UseUserDataType;
    }
    return {
      isError: false,
      isLoading: true,
      data: undefined,
      currentData: undefined,
      fulfilledTimeStamp: new Date().getTime(),
      error: undefined,
      isFetching: false,
      isSuccess: false,
      isUninitialized: false,
      status: QueryStatus.pending,
      refetch: noRefetch,
    } satisfies UseUserDataType;
  }
  return undefined;
}

let mapping: Mapping | null = null;

type UseMappingInitTrueResult = {
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
};

/**
 * Mapping hook to get the mapping instance inside React components.
 * Use with `init: true` to initialize the mapping instance in a
 * central component, and block rendering until initialization is
 * done. After that, use without argument to conveniently get the
 * mapping instance directly.
 * @param init - Whether to initialize the mapping instance
 * @returns Mapping instance or fetch result
 */
export function useMapping(props: { init: true }): UseMappingInitTrueResult;
export function useMapping(props?: { init: false }): Mapping;
export function useMapping({ init } = { init: false }): unknown {
  const mappingResult = useFetchMappingQuery(undefined, {
    skip: !init || !!mapping,
  });

  const memoizedMapping = useMemo(() => {
    if (!mapping && mappingResult.isSuccess) {
      mapping = createMappingInstance({
        mappingData: mappingResult.data,
      });
    }
    return mapping;
  }, [mappingResult.isSuccess, mappingResult.data]);

  return init ? mappingResult : memoizedMapping;
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
