import { isNumber, isRecord } from "./primitives";

export function hasData<T>(obj: unknown): obj is T & { data: unknown } {
  return isRecord(obj) && "data" in obj;
}

export function hasResults<T>(obj: unknown): obj is T & { results: unknown } {
  return isRecord(obj) && "results" in obj;
}

export function hasObjects<T>(obj: unknown): obj is T & { objects: unknown } {
  return isRecord(obj) && "objects" in obj;
}

export function isObjectWithId<T>(obj: unknown): obj is T & { id: number } {
  return isRecord(obj) && "id" in obj && isNumber(obj.id);
}
