import {
  DateInfo,
  ScheduleEvent,
  TReservation,
} from "@timeedit/registration-shared";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { Event } from "react-big-calendar";

interface ExtraEventData {
  data: {
    reservedWeeks?: number[];
    name: string;
    allocationObjectId: number;
    actualStart: DateInfo;
    actualEnd: DateInfo;
    hasConflict: boolean;
    trackNumber?: number;
    reservations: TReservation[];
  };
  id: string;
  body?: ReactNode;
  footer?: {
    wantToShowConflict: boolean;
    wantToShowSuccess: boolean;
    text?: string;
  };
}

export type CalendarEvent = Event &
  Required<Pick<Event, "end" | "start" | "title">> &
  ExtraEventData;

interface CreateEventProps {
  reservations: ScheduleEvent[];
}

/**
 * Be aware that start and end time objects are Date objects created from
 * times converted to a timezone based on user settings. They cannot be used
 * to go back to UTC! For anything other than displaying times in the
 * configured timezone, use the information on data.actualStart and
 * data.actualEnd
 */
export function createEvents({ reservations }: CreateEventProps) {
  const events: CalendarEvent[] =
    reservations.map((reservation) => {
      const event: CalendarEvent = {
        title: "",
        end: dayjs(reservation.end.local).toDate(),
        start: dayjs(reservation.start.local).toDate(),
        data: {
          name: reservation.name,
          allocationObjectId: reservation.trackId,
          actualStart: reservation.start,
          actualEnd: reservation.end,
          hasConflict: reservation.allocationConflicts.length > 0,
          reservations: [reservation.reservation],
        },
        id: `${reservation.id}_${reservation.trackId}`, // This need to be unique
      };
      return event;
    }) ?? [];

  return events;
}
