import { RouterProvider } from "react-router-dom";
import { getRouter } from "./Router";
import { useAppSelector } from "../redux/hooks";
import { useMemo } from "react";

export function RouterWrapper() {
  const { loginData } = useAppSelector((state) => state.login);

  const router = useMemo(
    () => getRouter(loginData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginData, loginData?.customerSignature]
  );

  return <RouterProvider router={router} />;
}
