import { useRef } from "react";

// The actual type is way to complex to pass around,
// with several levels of generics.
interface RTKQueryMutationSubscription {
  abort: () => void;
  reset: () => void;
}

export function useMutationSubscriptionRef() {
  return useRef<RTKQueryMutationSubscription>();
}
