import {
  FetchArgs,
  fetchBaseQuery,
  BaseQueryFn,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { logger } from "@timeedit/registration-components";
import {
  getRegionalUrlForEnv,
  getRegionFromAccessToken,
  isDefined,
  isSuccessHandlerResponse,
} from "@timeedit/registration-shared";
import { getAccessToken } from "@timeedit/ui-components";
import { z } from "zod";

/**
 * Base query for RTK Query with optional validation. Other custom base query
 * features should be implemented as additional optional parameters on the
 * extraOptions object in this baseQuery function.
 *
 * Extra options are passed to the `builder.query` constructor.
 *
 * @example
 * endpoints(builder) {
 *   return {
 *     fetchCourses: builder.query<Course[], CoursesQueryParams | undefined>({
 *       query(params) { return { url: "/courses", params, }; },
 *       extraOptions: { validation: z.array(Course) }◄─┐
 *     }),               **Validation Zod object** ─────┘
 *   };
 * },
 */
export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  // arg is assumed to be the same as the type of the query response: the thing
  // that is supposed to be verified. In the function, any parameter can then
  // be used as arguments to validating functions of other parameters.
  //
  // Example:
  // const thing = { timezone: "Pacific/Honolulu", start: "2021-09-01" }
  // const Thing = (schema: ThingSchema) => ThingSchema(schema.timezone); ◄─┐
  //    /*the parameter is chosen as part of the validation function*/ ─────┘
  //
  // const thingResult = Thing(thing).safeParse(thing)
  //
  // any is used because we do not control the implementation of this type
  // constructor and I can't figure out how to use a generic here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { validation?: z.ZodTypeAny | ((arg: any) => z.ZodTypeAny) }
> = async function baseQuery(args, api, extraOptions = {}) {
  const token = getAccessToken();
  const environment = import.meta.env.VITE_TE_APP_ENV;

  const region = getRegionFromAccessToken(token, logger);
  const baseUrl = getRegionalUrlForEnv("registration", region, environment);

  const originalResult = await fetchBaseQuery({
    baseUrl: `${baseUrl}/api`,
    prepareHeaders: (headers) => {
      if (token !== null) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const returnResult = { ...originalResult };

  // Pick out the data property if the response is the shape of our successHandler on the back end.
  if (isSuccessHandlerResponse(returnResult.data)) {
    returnResult.data = returnResult.data.data;
  }

  if (extraOptions.validation && isDefined(returnResult.data)) {
    let zodValidation = extraOptions.validation;
    if (typeof zodValidation === "function") {
      zodValidation = zodValidation(returnResult.data);
    }
    if (!originalResult.error) {
      const parsedResult = zodValidation.safeParse(returnResult.data);

      if (!parsedResult.success) {
        returnResult.error = {
          status: "CUSTOM_ERROR",
          data: parsedResult.error,
          error: parsedResult.error.name,
        };
        returnResult.data = undefined;
      } else {
        returnResult.data = parsedResult.data;
      }
    }
  }

  return returnResult;
};
