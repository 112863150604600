import { EAppId, ETokenType } from "@timeedit/types/lib/enums";
import { useUserData } from "../../redux/hooks";
import { useFetchTeOrganizationQuery } from "../../redux/api/te";
import { getEndpoints } from "../RouteTypes";
import {
  ITEToken,
  TNavigation,
  TOrganizationPublic,
} from "@timeedit/types/lib/types";
import { useContext } from "react";
import { EOrganizationVisibility } from "@timeedit/types/lib/enums/organizationVisibility.enum";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import { TopBar, TopBarContent, AuthContext } from "@timeedit/ui-components";

interface ResponsiveTopBarProps {
  isDesktop: boolean;
}

export function ResponsiveTopBar({ isDesktop }: ResponsiveTopBarProps) {
  const user = useUserData();

  const org = useFetchTeOrganizationQuery(
    { organizationId: user.data?.organizationId },
    { skip: user.isLoading || user.isError }
  );

  const logout =
    useContext(AuthContext)?.logout ?? (() => alert("Logout failed"));

  const ENDPOINTS = getEndpoints();
  const navigation: TNavigation[] = isDesktop
    ? [
        {
          label: "Registration",
          path: `${ENDPOINTS.registration.path}`,
          visible: true,
        },
        {
          label: "Schedule",
          path: `${ENDPOINTS.schedule.path}`,
          visible: true,
        },
      ]
    : [];

  const isSuccess = user.isSuccess && org.isSuccess;

  // TODO: Make sure these defaults make sense once the topbar is
  // finished. We should not guessabout the user's region or the type
  // of the token. Either we need to find those out in a reliable way,
  // or change the type in @timeedit/types to be optional.
  const userData = isSuccess
    ? {
        ...user.data,
        region: user.data.region ?? ERegion.EU_EES,
        type: user.data.type ?? ETokenType.USER,
      }
    : ({} as ITEToken);

  const orgData = isSuccess
    ? {
        ...org.data,
        visibility: EOrganizationVisibility.PUBLIC,
        region: org.data.region ?? ERegion.EU_EES,
      }
    : ({} as TOrganizationPublic);

  const parts = {
    left: (
      <>
        <TopBarContent.OrganizationLogo organization={orgData} />
        <TopBarContent.OrganizationName organization={orgData} />
        <TopBarContent.EnvTag appEnv={import.meta.env.VITE_TE_APP_ENV} />
      </>
    ),
    center: (
      <TopBarContent.NavigationMenu
        navigation={navigation}
        selected={isDesktop ? findSelected(location.pathname) : undefined}
      />
    ),
    right: (
      <TopBarContent.UserDropdown
        name={userData?.email || ""}
        onLogout={logout}
      />
    ),
  };
  return <TopBar currentProduct={EAppId.TE_REGISTRATION} parts={parts} />;

  function findSelected(includingPath: string) {
    return navigation.find(({ path }) => includingPath.includes(path))?.path;
  }
}
