import {
  Navigate,
  RouteObject,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import { Schedule } from "./SchedulePage/Schedule";
import { RegistrationPage } from "./RegistrationPage";
import { ReportsPage } from "./ReportsPage";
import { AllocationObjectsPage } from "@timeedit/registration-components";
import { CachePage } from "./CachePage";
import { getEndpoints } from "./RouteTypes";
import { Root } from "./RootPage/Root";
import {
  customerPathFromSignature,
  ITEToken,
} from "@timeedit/registration-shared";
import { useNavigateWithSignature } from "./useNavigateWithSignature";
import { useEffect } from "react";

function NavigateWithSignature() {
  const navigateWithSignature = useNavigateWithSignature();
  const location = useLocation();

  useEffect(() => {
    navigateWithSignature({
      pathname: location.pathname,
      search: location.search,
    });
  }, [location.pathname, location.search, navigateWithSignature]);

  return null;
}

export function getRouter(authUser: ITEToken | undefined) {
  const customerSignature = authUser?.customerSignature ?? "";
  const customerPath = customerPathFromSignature(customerSignature);
  const routes: RouteObject[] = [
    {
      path: `/${customerPath}`,
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Navigate to={getEndpoints().registration.path} />,
        },
        {
          path: getEndpoints().reports.path,
          element: <ReportsPage />,
        },
        {
          path: getEndpoints().cache.path,
          element: <CachePage />,
        },
        {
          path: getEndpoints().schedule.path,
          element: <Schedule />,
        },
        {
          path: getEndpoints().registration.path,
          element: <RegistrationPage />,
          children: [
            {
              path: ":id",
              element: <AllocationObjectsPage />,
            },
          ],
        },
      ].map((route) => ({ errorElement: <ErrorPage />, ...route })),
    },
    {
      path: "/*",
      element: <NavigateWithSignature />,
    },
  ];

  return createBrowserRouter(routes);
}
