import { z } from "zod";
import {
  AllocationGroupState,
  AllocationSignal,
  AllocationStatus,
} from "./common";
import { TReservation, TTEObject } from "./types";
import {
  DateTransform,
  NonNegativeIntegerTransform,
  NumberTransform,
} from "./transforms";
import { DedicatedTrack } from "./allocate";
import { ZUnsafeRecord } from "./util";

//-----------------------------------------------------------------------------
// --> Seats
//-----------------------------------------------------------------------------

export const Seats = z.object({
  taken: NonNegativeIntegerTransform,
  available: NonNegativeIntegerTransform,
  total: NonNegativeIntegerTransform,
  raw: z.object({
    taken: NonNegativeIntegerTransform,
    available: NonNegativeIntegerTransform,
    total: NonNegativeIntegerTransform,
    buffer: NonNegativeIntegerTransform,
  }),
});

export type Seats = z.infer<typeof Seats>;

//-----------------------------------------------------------------------------
// --> CatalogTEObject
//-----------------------------------------------------------------------------

export const CatalogTEObject = TTEObject?.partial().and(
  TTEObject.pick({
    id: true,
    extId: true,
    fields: true,
    members: true,
    types: true,
    relations: true,
  })
);
export type CatalogTEObject = z.infer<typeof CatalogTEObject>;

//-----------------------------------------------------------------------------
// --> BaseCatalogObject
//-----------------------------------------------------------------------------

export const BaseCatalogObject = z.object({
  id: NumberTransform,
  label: z.string(),
});

export type BaseCatalogObject = z.infer<typeof BaseCatalogObject>;

//-----------------------------------------------------------------------------
// --> CatalogCourse
//-----------------------------------------------------------------------------

export const CatalogCourse = BaseCatalogObject.extend({
  extId: z.string(),
  typeId: z.number(),
  children: z.array(z.number()).optional().default([]),
  teObject: CatalogTEObject,
});

export type CatalogCourse = z.infer<typeof CatalogCourse>;

//-----------------------------------------------------------------------------
// --> CatalogTrackList
//-----------------------------------------------------------------------------

export const CatalogTrackList = BaseCatalogObject.extend({
  children: z.array(z.number()).optional().default([]),
  parentId: z.number().int().positive(),
});

export type CatalogTrackList = z.infer<typeof CatalogTrackList>;

//-----------------------------------------------------------------------------
// --> CatalogTrack
//-----------------------------------------------------------------------------

export const CatalogTrack = BaseCatalogObject.extend({
  extId: z.string(),
  typeId: z.number(),
  parentId: z.number().int().positive(),
  teObject: CatalogTEObject,
  links: z.array(z.number()),
});

export type CatalogTrack = z.infer<typeof CatalogTrack>;

//-----------------------------------------------------------------------------
// --> Catalog
//-----------------------------------------------------------------------------

export const Catalog = z.object({
  courses: ZUnsafeRecord(z.string(), CatalogCourse),
  trackLists: ZUnsafeRecord(z.string(), CatalogTrackList),
  tracks: ZUnsafeRecord(z.string(), CatalogTrack),
});

export type Catalog = z.infer<typeof Catalog>;

//-----------------------------------------------------------------------------
// --> RegistrationCourse
//-----------------------------------------------------------------------------

export const RegistrationCourse = CatalogCourse.extend({
  open: z.boolean().optional(),
  showInRegistration: z
    .boolean()
    .optional()
    .transform((it) => !!it),
});

export type RegistrationCourse = z.infer<typeof RegistrationCourse>;

//-----------------------------------------------------------------------------
// --> RegistrationTrackList
//-----------------------------------------------------------------------------

export const RegistrationTrackList = CatalogTrackList.extend({
  state: AllocationGroupState.optional().default("SELECT"),
  allocationSignal: AllocationSignal.optional().default("ISSUE"),
  open: z.boolean().optional(),
  showInRegistration: z
    .boolean()
    .optional()
    .transform((it) => !!it),
});

export type RegistrationTrackList = z.infer<typeof RegistrationTrackList>;

//-----------------------------------------------------------------------------
// --> RegistrationTrack
//-----------------------------------------------------------------------------

export const RegistrationTrack = CatalogTrack.extend({
  seats: Seats.optional().default({
    taken: 0,
    available: 0,
    total: 0,
    raw: { taken: 0, available: 0, total: 0, buffer: 0 },
  }),
  allocationStatus: AllocationStatus.optional().default("NOT_ALLOCATED"),
  open: z.boolean().optional(),
  dedicated: DedicatedTrack.optional().default({
    kind: "none",
    data: undefined,
  }),
  hidden: z
    .boolean()
    .optional()
    .transform((it) => !!it),
  showInRegistration: z
    .boolean()
    .optional()
    .transform((it) => !!it),
});

export type RegistrationTrack = z.infer<typeof RegistrationTrack>;

//-----------------------------------------------------------------------------
// --> ScheduleEvent
//-----------------------------------------------------------------------------

export const ScheduleEvent = (timezone: string) =>
  z.object({
    id: z.number(),
    start: DateTransform(timezone),
    end: DateTransform(timezone),
    name: z.string(),
    reservation: TReservation,
    trackId: z.number(),
    allocationConflicts: z.array(
      z.object({
        trackId: z.number(),
        reservationId: z.number(),
      })
    ),
  });

export type ScheduleEvent = z.infer<ReturnType<typeof ScheduleEvent>>;

//-----------------------------------------------------------------------------
// --> ScheduleConflict
//-----------------------------------------------------------------------------

export const scheduleConflictKinds = ["ALLOCATION"] as const;

export const ScheduleConflictKind = z.enum(scheduleConflictKinds);

export type ScheduleConflictKind = z.infer<typeof ScheduleConflictKind>;

export const ScheduleConflict = z.object({
  kind: ScheduleConflictKind,
  tracks: z.tuple([z.number(), z.number()]),
  events: z.array(z.tuple([z.number(), z.number()])),
});

export type ScheduleConflict = z.infer<typeof ScheduleConflict>;

//-----------------------------------------------------------------------------
// --> Schedule
//-----------------------------------------------------------------------------

const ScheduleSchema = (timezone: string) =>
  z.object({
    events: z.array(ScheduleEvent(timezone)),
    conflicts: z.array(ScheduleConflict),
    timezone: z.string(),
  });

export const Schedule = (schema: z.input<ReturnType<typeof ScheduleSchema>>) =>
  ScheduleSchema(schema.timezone).omit({ timezone: true });

export type Schedule = z.infer<ReturnType<typeof Schedule>>;

//-----------------------------------------------------------------------------
// --> Registration
//-----------------------------------------------------------------------------

const RegistrationSchema = (timezone: string) =>
  z.object({
    courses: ZUnsafeRecord(z.string(), RegistrationCourse),
    trackLists: ZUnsafeRecord(z.string(), RegistrationTrackList),
    tracks: ZUnsafeRecord(z.string(), RegistrationTrack),
    events: z.array(ScheduleEvent(timezone)),
    conflicts: z.array(ScheduleConflict),
    dateTime: DateTransform(timezone).optional(),
    timezone: z.string(),
    studentId: z.number(),
  });

export const Registration = (
  schema: z.input<ReturnType<typeof RegistrationSchema>>
) => RegistrationSchema(schema.timezone);

export type Registration = z.infer<ReturnType<typeof Registration>>;

//-----------------------------------------------------------------------------
// --> Registration
//-----------------------------------------------------------------------------

export const registrationModes = ["teacher", "student"] as const;
export const RegistrationMode = z.enum(registrationModes);
export type RegistrationMode = z.infer<typeof RegistrationMode>;
