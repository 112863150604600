export function isRecord(item: unknown): item is Record<string, unknown> {
  return typeof item === "object" && item !== null && !isArray(item);
}

export function isPositiveNumber(item: unknown): boolean {
  if (!isNumber(item)) return false;
  return item > 0;
}

export function isNumber(item: unknown): item is number {
  return typeof item === "number" && !isNaN(item);
}

export function isBoolean(item: unknown): item is boolean {
  return typeof item === "boolean";
}

export function isString(item: unknown): item is string {
  return typeof item === "string";
}

export function isArray(item: unknown): item is Array<unknown> {
  return Array.isArray(item);
}

export function isArrayWithItems(item: unknown): item is Array<unknown> {
  return Array.isArray(item) && item.length !== 0;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return <T>value !== undefined && <T>value !== null;
}

export function isNullish(
  item: unknown
): item is null | typeof NaN | undefined {
  return (
    (typeof item === "number" && isNaN(item)) ||
    item === undefined ||
    item === null
  );
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
// Keeps the type on object entries
export function getEntries<T extends object>(obj: T) {
  return Object.entries(obj) as Entries<T>;
}
