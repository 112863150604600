import { isDefined } from "../typechecks";

type LogFn = (arg: unknown, ...rest: unknown[]) => void;

interface ConsoleLogger {
  info(arg: unknown, ...rest: unknown[]): void;
  warn(arg: unknown, ...rest: unknown[]): void;
  debug(arg: unknown, ...rest: unknown[]): void;
  error(arg: unknown, ...rest: unknown[]): void;
}

interface PinoLogger extends ConsoleLogger {
  trace(arg: unknown, ...rest: unknown[]): void;
  fatal(arg: unknown, ...rest: unknown[]): void;
}

export type Logger = ConsoleLogger | PinoLogger;
export type LogTransformer = (logFn: LogFn) => LogFn;

/**
 * A logger wrapper that encapsulates the logger implementation. This allows
 * us to use a different logger implementation in different environments.
 */
export class LogService {
  logger: Logger;
  transformer?: LogTransformer;

  constructor(logger: Logger, transformer?: LogTransformer) {
    this.logger = logger;
    this.transformer = transformer;
  }

  /**
   * Logs an info message.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.info
   */
  info(arg: unknown, ...rest: unknown[]) {
    if (isDefined(this.transformer)) {
      this.transformer(this.logger.info.bind(this.logger))(arg, ...rest);
      return;
    }
    this.logger.info(arg, ...rest);
  }

  /**
   * Logs a warning message.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.debug
   */
  warn(arg: unknown, ...rest: unknown[]) {
    if (isDefined(this.transformer)) {
      this.transformer(this.logger.warn.bind(this.logger))(arg, ...rest);
      return;
    }
    this.logger.warn(arg, ...rest);
  }

  /**
   * Logs a debug message. Used for low level debug information.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.debug
   */
  debug(arg: unknown, ...rest: unknown[]) {
    if (isDefined(this.transformer)) {
      this.transformer(this.logger.debug.bind(this.logger))(arg, ...rest);
      return;
    }
    this.logger.debug(arg, ...rest);
  }

  /**
   * Logs a error message. Used for when something goes wrong.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.error
   */
  error(arg: unknown, ...rest: unknown[]) {
    if (isDefined(this.transformer)) {
      this.transformer(this.logger.error.bind(this.logger))(arg, ...rest);
      return;
    }
    this.logger.error(arg, ...rest);
  }

  trace(arg: unknown, ...rest: unknown[]) {
    if ("trace" in this.logger) {
      if (isDefined(this.transformer)) {
        this.transformer(this.logger.trace.bind(this.logger))(arg, ...rest);
        return;
      }
      this.logger.trace(arg, ...rest);
    } else {
      if (isDefined(this.transformer)) {
        this.transformer(this.logger.debug.bind(this.logger))(arg, ...rest);
        return;
      }
      this.logger.debug(arg, ...rest);
    }
  }

  fatal(arg: unknown, ...rest: unknown[]) {
    if ("fatal" in this.logger) {
      if (isDefined(this.transformer)) {
        this.transformer(this.logger.fatal.bind(this.logger))(arg, ...rest);
        return;
      }
      this.logger.fatal(arg, ...rest);
    } else {
      if (isDefined(this.transformer)) {
        this.transformer(this.logger.error.bind(this.logger))(arg, ...rest);
        return;
      }
      this.logger.error(arg, ...rest);
    }
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs info messages
   */
  createInfoFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.info(`${baseMessage} ${arg}`, ...rest);
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs debug messages
   */
  createDebugFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.debug(`${baseMessage} ${arg}`, ...rest);
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs errors
   */
  createErrorFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.error(`${baseMessage} ${arg}`, ...rest);
  }
}
