import { isArray, isNumber, isRecord, isString } from "../typechecks";

export function jsonToText(data: unknown) {
  return safeToJSON(data).replace(/\\n|["\\{}]/g, " ");
}

/**
 * Function to stringify things that is guaranteed to return a string,
 * avoiding json parse errors where they are not useful.
 */
export function safeToJSON(error: unknown, prettyPrintIndentLevel?: number) {
  try {
    if (prettyPrintIndentLevel !== undefined) {
      return JSON.stringify(error, undefined, prettyPrintIndentLevel);
    }
    return JSON.stringify(error);
  } catch {
    if (isString(error)) {
      return error;
    }
    if (isNumber(error)) {
      return error.toString();
    }
    return "";
  }
}

/**
 * Function to parse json strings that is guaranteed to return an object,
 * avoiding json parse errors where they are not useful.
 */
export function safeFromJSONObject(
  jsonString: unknown
): Record<string, unknown> {
  try {
    if (isString(jsonString)) {
      const value = JSON.parse(jsonString);
      if (isRecord(value)) {
        return value;
      }
      return {};
    }
    return {};
  } catch {
    return {};
  }
}

/**
 * Function to parse json strings that is guaranteed to return an object,
 * avoiding json parse errors where they are not useful.
 */
export function safeFromJSONArray(jsonString: unknown): unknown[] {
  try {
    if (isString(jsonString)) {
      const value = JSON.parse(jsonString);
      if (isArray(value)) {
        return value;
      }
      return [];
    }
    return [];
  } catch {
    return [];
  }
}
