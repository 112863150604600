import { z } from "zod";
import { AllocationSignal, ObjectIdsWithType } from "./common";
import { CatalogTrack } from "./registration";
import { AddStudentsToTracksOverrides, ExactSearch } from "./allocate";
import { TTEFieldValue } from "./types";
import { IntegerTransform } from "./transforms";
import { ZUnsafeRecord } from "./util";

export const FindReservationsBody = z.object({
  objectIds: z.array(z.number().nonnegative().int()),
  typeId: z.number().nonnegative().int(),
  useCache: z.boolean(),
});
export type FindReservationsBody = z.infer<typeof FindReservationsBody>;

//-----------------------------------------------------------------------------
// --> Load Request
//-----------------------------------------------------------------------------

export const LoadBody = z
  .object({
    ids: z.array(z.number().nonnegative().int()).optional(),
    typeId: z.number().nonnegative().int().optional(),
    useCache: z.boolean().optional(),
    limit: z.number().nonnegative().int().optional(),
    fields: z.array(z.string().or(z.number().nonnegative().int())).optional(),
    relations: ObjectIdsWithType.optional(),
    members: ObjectIdsWithType.optional(),
    text: z.string().optional(),
    category: z.array(ExactSearch).optional(),
  })
  .refine((schema) => {
    const hasNoIdsAndNoLimit = !schema.ids?.length && !schema.limit;
    return !hasNoIdsAndNoLimit;
  }, "Limit required when no ids are specified");
export type LoadBody = z.infer<typeof LoadBody>;

export const GetStaffCoursesBody = z.object({
  fields: z
    .object({
      exactSearchFields: z.array(ExactSearch).optional(),
      generalSearchFieldIds: z.array(IntegerTransform).optional(),
      searchText: z.string().optional(),
    })
    .optional(),
  idsToFilter: z.array(IntegerTransform).optional(),
  relations: ObjectIdsWithType.optional(),
});
export type GetStaffCoursesBody = z.infer<typeof GetStaffCoursesBody>;

//-----------------------------------------------------------------------------
// --> Save Request
//-----------------------------------------------------------------------------

export const SaveBody = z.array(
  z.object({ id: z.number(), fields: z.array(TTEFieldValue) })
);
export type SaveBody = z.infer<typeof SaveBody>;

//-----------------------------------------------------------------------------
// --> Allocate Request
//-----------------------------------------------------------------------------

export const conflictControlStrategies = ["partial", "none"] as const;
export const ConflictControlStrategy = z.enum(conflictControlStrategies);
export type ConflictControlStrategy = z.infer<typeof ConflictControlStrategy>;

export const StudentInfo = ZUnsafeRecord(
  z.string(),
  z.array(z.number()).or(z.undefined())
);
export type StudentInfo = z.infer<typeof StudentInfo>;

export const AllocateRequest = z.object({
  studentInfo: StudentInfo,
  conflictControl: ConflictControlStrategy,
  overrides: AddStudentsToTracksOverrides.optional(),
});
export type AllocateRequest = z.infer<typeof AllocateRequest>;

//-----------------------------------------------------------------------------
// --> Deallocate Request
//-----------------------------------------------------------------------------

export const DeallocateRequest = z.object({
  studentInfo: StudentInfo,
});

export type DeallocateRequest = z.infer<typeof DeallocateRequest>;

//-----------------------------------------------------------------------------
// --> Swap Request
//-----------------------------------------------------------------------------

export const SwapRequest = z.object({
  studentId: z.number(),
  newObjectId: z.number(),
  prevObjectIds: z.array(z.number()),
  conflictControl: ConflictControlStrategy,
  overrides: AddStudentsToTracksOverrides.optional(),
});
export type SwapRequest = z.infer<typeof SwapRequest>;

//-----------------------------------------------------------------------------
// --> ScheduleRequest
//-----------------------------------------------------------------------------

export const ScheduleRequest = z.object({
  allocationObjects: ZUnsafeRecord(z.string(), CatalogTrack),
});

export type ScheduleRequest = z.infer<typeof ScheduleRequest>;

//-----------------------------------------------------------------------------
// --> SignalsPayload
//-----------------------------------------------------------------------------

export const SignalsPayload = z.object({
  summary: z.record(AllocationSignal, z.number()),
});

export type SignalsPayload = z.infer<typeof SignalsPayload>;

//-----------------------------------------------------------------------------
// --> PacemakerSwapResponse
//-----------------------------------------------------------------------------

export const PacemakerSwapResponse = z.object({
  results: z.array(
    z.object({
      status: z.number(),
      message: z
        .object({
          status: z.number(),
          message: z.string(),
        })
        .or(z.string()),
      memberObjectId: z.number().optional(),
      groupObjectId: z.number().optional(),
      operation: z.enum(["add", "remove"]),
    })
  ),
});

export type PacemakerSwapResponse = z.infer<typeof PacemakerSwapResponse>;

//-----------------------------------------------------------------------------
// --> Optimize
//-----------------------------------------------------------------------------

export const OptimizeAllocationBody = z.object({
  trackIds: z.array(z.number()),
});

export type OptimizeAllocationBody = z.infer<typeof OptimizeAllocationBody>;

export const OptimizeAllBody = GetStaffCoursesBody;
export type OptimizeAllBody = z.infer<typeof OptimizeAllBody>;
