import { exhaustiveMatchingGuard } from "../typechecks";
import { AllocationSignal, Registration } from "../zod";

export interface CalculateSignalsSummaryProps {
  registration: Registration;
}

/**
 * Calculates allocation group "signal" summary
 *
 * @param catalog - The most recent course catalog data
 * @returns
 * A summary of how many signals of each type there are.
 */

export function calculateSignalSummary({
  registration,
}: CalculateSignalsSummaryProps) {
  const { trackLists } = registration;
  const summary: Record<AllocationSignal, number> = {
    OK: 0,
    ISSUE: 0,
    PENDING: 0,
  };

  for (const id in trackLists) {
    const allocationSignal = trackLists[id]?.allocationSignal;

    if (!allocationSignal) continue;
    if (!trackLists[id]?.showInRegistration) continue;

    switch (allocationSignal) {
      case "ISSUE": {
        summary.ISSUE++;
        break;
      }
      case "PENDING": {
        summary.PENDING++;
        break;
      }
      case "OK": {
        summary.OK++;
        break;
      }
      default: {
        exhaustiveMatchingGuard(allocationSignal);
      }
    }
  }

  return summary;
}
