import { z } from "zod";

export const OptimizerMeta = z.object({
  userId: z.string(),
  timestamp: z.number(),
});
export type OptimizerMeta = z.infer<typeof OptimizerMeta>;

export const OptimizerOutputSchema = z.object({
  outputPath: z.string(),
  jobId: z.string(),
  internalJobId: z.string(),
  jobType: z.string(),
  status: z.string(),
});
export type OptimizerOutputSchema = z.infer<typeof OptimizerOutputSchema>;

export const OptimizerResult = z.object({
  message: z.string(),
  solution: z.object({
    assignments: z.array(
      z.object({
        studentSetGroupId: z.number(),
        students: z.array(z.number()),
      })
    ),
  }),
});

export const OptimizerResponse = z.object({
  build: z.string(),
  dataVersion: z.number(),
  exit_status: z.string(),
  id: z.string(),
  meta: OptimizerMeta,
  result: OptimizerResult,
});
export type OptimizerResponse = z.infer<typeof OptimizerResponse>;
