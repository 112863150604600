import { customerPathFromSignature } from "@timeedit/registration-shared";
import { useCallback } from "react";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";

export function useCustomerNavigation(
  customerSignature: string | null | undefined,
  root: string
) {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      if (!customerSignature) {
        return navigate(to, options);
      }
      const customerPath = customerPathFromSignature(customerSignature);
      const customerPathPrefix = `/${customerPath}`;
      const rootPath = `${customerPathPrefix}/${root}`;

      const getPathname = (to: string) => {
        return to.startsWith(customerPathPrefix)
          ? to
          : `${customerPathPrefix}${to}`;
      };

      const isUndefinedPath = (pathname: string) =>
        pathname === location.pathname;

      if (typeof to === "string") {
        if (!to.startsWith("/")) {
          return navigate(to, options);
        }

        const pathname = getPathname(to);

        if (isUndefinedPath(pathname)) {
          return navigate(rootPath);
        }

        return navigate({ pathname, search: location.search }, options);
      } else {
        if (!to.pathname) {
          return navigate(rootPath);
        } else {
          if (!to.pathname.startsWith("/")) {
            return navigate(to, options);
          }
          const pathname = getPathname(to.pathname);
          if (isUndefinedPath(pathname)) {
            return navigate(rootPath);
          }

          return navigate({ ...to, pathname }, options);
        }
      }
    },
    [customerSignature, location.pathname, location.search, navigate, root]
  );
}
