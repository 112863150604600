import { useCustomerNavigation } from "@timeedit/registration-components";
import { useCallback } from "react";
import { NavigateOptions, To } from "react-router-dom";
import { getEndpoints } from "./RouteTypes";
import { useAppSelector } from "../redux/hooks";

export function useNavigateWithSignature() {
  const { loginData } = useAppSelector((state) => state.login);

  const customerSignature = loginData?.customerSignature;
  const rootPath = getEndpoints().registration.path;
  const customerNavigation = useCustomerNavigation(customerSignature, rootPath);

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      return customerNavigation(to, options);
    },
    [customerNavigation]
  );
}
