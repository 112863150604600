/* eslint-disable */
import "../../index.scss";
import styles from "./rootPage.module.scss";
import { Outlet, useLocation, Location, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getEndpoints } from "../RouteTypes";
import {
  customerPathFromSignature,
  isDefined,
  RegistrationError,
  translations,
} from "@timeedit/registration-shared";
import { Navbar, Spin, useIsDesktop } from "@timeedit/registration-components";
import { useLanguage } from "../../lang/translations";
import {
  isValidBasePath,
  removeFirstLastBackslash,
  removePathParameters,
} from "../URLUtil";
import { CalendarOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { LocaleWrapper } from "../../lang/locale";
import { ResponsiveTopBar } from "./DesktopNavigation";
import { useRef } from "react";
import { useAppSelector, useMapping } from "../../redux/hooks";
import { defaultTheme } from "@timeedit/ui-components/lib/src/assets/theme/default";
import { ThemeWrapper } from "@timeedit/ui-components";

export function Root() {
  const ENDPOINTS = getEndpoints();
  const navigationEndpoints = [
    {
      ...ENDPOINTS.registration,
      icon: <UnorderedListOutlined alt={ENDPOINTS.registration.name} />,
    },
    {
      ...ENDPOINTS.schedule,
      icon: <CalendarOutlined alt={ENDPOINTS.schedule.name} />,
    },
  ];
  const parameters = useParams<{
    customerSignature: string;
  }>();

  const { currentLanguage } = useLanguage();
  const { loginData } = useAppSelector((state) => state.login);

  const location = useLocation();
  const navigate = useNavigate();

  const headerRef = useRef<HTMLElement>(null);

  const isDesktop = useIsDesktop();
  const index = !isDesktop ? getIndexFromLocation(location) : 0;

  const mapping = useMapping({ init: true });

  if (mapping.isLoading || mapping.isFetching) {
    return <Spin contained />;
  }

  if (mapping.isError) {
    throw new RegistrationError({ errors: [mapping.error] });
  }

  return (
    <ThemeWrapper theme={defaultTheme}>
      <LocaleWrapper localeId={currentLanguage}>
        <Layout>
          <Header ref={headerRef} className={styles.rootPageHeader}>
            <ResponsiveTopBar isDesktop={isDesktop} />
          </Header>

          <Content
            className={styles.rootPageContent}
            style={{
              paddingTop: `${Math.max(
                headerRef.current?.getBoundingClientRect()?.height ?? 0,
                48
              )}px`,
            }}
          >
            <div className={styles.rootPageOutlet}>
              <Outlet />
            </div>
          </Content>
          {!isDesktop && (
            <Navbar
              index={index}
              onChange={navigateToIndex}
              Endpoints={navigationEndpoints}
              tabsProps={{ className: styles.navbarTabs }}
              navigateTranslation={translations.navigate}
            />
          )}
        </Layout>
      </LocaleWrapper>
    </ThemeWrapper>
  );

  function isRoot(path: string) {
    return path === ENDPOINTS.root.path;
  }

  function getEndpointFromIndex(index: number) {
    const endpoint = navigationEndpoints.find((_, value) => value === index);

    if (isDefined(endpoint)) {
      return endpoint.path;
    }

    throw new Error(`There was no endpoint found with index ${index}`);
  }

  function getBasePathFromLocation(location: Location) {
    const pathName = location.pathname;

    const pathWithoutParameters = removePathParameters({
      path: pathName,
      params: parameters,
    });

    const basePath = removeFirstLastBackslash(pathWithoutParameters);

    if (
      isValidBasePath(basePath, ENDPOINTS, loginData?.customerSignature || "")
    ) {
      return basePath;
    }

    throw new Error(
      `Invalid base path ${basePath}, make sure it exists in endpoints`
    );
  }

  function getIndexFromLocation(location: Location) {
    const basePath = getBasePathFromLocation(location);

    if (isRoot(basePath)) {
      return navigationEndpoints.findIndex(
        ({ path }) => path === ENDPOINTS.registration.path
      );
    }

    // Can give -1 if it doesn't find
    return navigationEndpoints.findIndex(({ path }) => basePath.includes(path));
  }

  function navigateToIndex(index: number) {
    const endpoint = getEndpointFromIndex(index);
    navigate(`${endpoint}`);
  }
}
