import {
  EAppProperties,
  EApplicationObjectTypeGroup,
} from "@timeedit/types/lib/enums";
import { MappingConfigBase } from "../mapping.types";

export const config = {
  activityField: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.ACTIVITY_TYPE,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.ACTIVITY_TYPE}`,
    required: true,
  },
  activityType: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.ACTIVITY_TYPE,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.ACTIVITY_TYPE}`,
    required: true,
  },
  allowRegistration: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.ALLOW_REGISTRATION,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.ALLOW_REGISTRATION}`,
    required: false,
  },
  autoAllocation: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.AUTO_ALLOCATION_ACTIVATED,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.AUTO_ALLOCATION_ACTIVATED}`,
    required: false,
  },
  buffer: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.BUFFER,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.BUFFER}`,
    required: false,
  },
  changeRequestName: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.CHANGE_REQUEST,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.CHANGE_REQUEST}-${EAppProperties.LABEL}`,
    required: false,
  },
  course: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.COURSE },
    key: `${EApplicationObjectTypeGroup.COURSE}`,
    required: true,
  },
  courseTitle: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.TITLE,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.TITLE}`,
    required: true,
  },
  courseLabel: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.LABEL}`,
    required: true,
  },
  courseRegistrationPeriodSet: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.REGISTRATION_PERIOD_SET,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.REGISTRATION_PERIOD_SET}`,
    required: true,
  },
  courseSeats: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.SEATS,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.SEATS}`,
    required: false,
  },
  dedicatedTrack: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.DEDICATETRACK,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.DEDICATETRACK}`,
    required: false,
  },
  endDate: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.END_DATE,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.END_DATE}`,
    required: true,
  },
  expectedEnrollment: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.SEATS,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.SEATS}`,
    required: true,
  },
  hideTrack: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.HIDETRACK,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.HIDETRACK}`,
    required: false,
  },
  linkedTrack: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.LINKTRACK,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.LINKTRACK}`,
    required: false,
  },
  maxStudents: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.MAX_STUDENTS,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.MAX_STUDENTS}`,
    required: true,
  },
  program: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.PROGRAM },
    key: `${EApplicationObjectTypeGroup.PROGRAM}`,
    required: false,
  },
  programName: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.PROGRAM,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.PROGRAM}-${EAppProperties.LABEL}`,
    required: false,
  },
  room: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.ROOM },
    key: `${EApplicationObjectTypeGroup.ROOM}`,
    required: false,
  },
  changeRequest: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.CHANGE_REQUEST },
    key: `${EApplicationObjectTypeGroup.CHANGE_REQUEST}`,
    required: false,
  },
  roomName: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.ROOM,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.ROOM}-${EAppProperties.LABEL}`,
    required: false,
  },
  roomSize: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.ROOM,
      appProperty: EAppProperties.SEATS,
    },
    key: `${EApplicationObjectTypeGroup.ROOM}-${EAppProperties.SEATS}`,
    required: false,
  },
  semester: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      appProperty: EAppProperties.SEMESTER,
    },
    key: `${EApplicationObjectTypeGroup.COURSE}-${EAppProperties.SEMESTER}`,
    required: false,
  },
  staff: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.STAFF },
    key: `${EApplicationObjectTypeGroup.STAFF}`,
    required: true,
  },
  startDate: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.START_DATE,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.START_DATE}`,
    required: true,
  },
  state: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.STATE,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.STATE}`,
    required: false,
  },
  student: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.STUDENT },
    key: `${EApplicationObjectTypeGroup.STUDENT}`,
    required: true,
  },
  studentName: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT}-${EAppProperties.LABEL}`,
    required: true,
  },
  track: {
    kind: "objectType",
    props: { objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}`,
    required: true,
  },
  trackAutoAllocationActivated: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.AUTO_ALLOCATION_ACTIVATED,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.AUTO_ALLOCATION_ACTIVATED}`,
    required: false,
  },
  trackDistributionRule: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: "DISTRIBUTION_RULE" as EAppProperties,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-DISTRIBUTION_RULE` as `${EApplicationObjectTypeGroup}-${EAppProperties}`,
    required: false,
  },
  trackName: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.LABEL,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.LABEL}`,
    required: false,
  },
  trackNumber: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.TRACK,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.TRACK}`,
    required: true,
  },
  waitlist: {
    kind: "field",
    props: {
      objectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      appProperty: EAppProperties.WAITLIST,
    },
    key: `${EApplicationObjectTypeGroup.STUDENT_SET_GROUP}-${EAppProperties.WAITLIST}`,
    required: false,
  },
} satisfies MappingConfigBase;
